import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import canaisAtendimentoJson from '../../assets/data/canaisAtendimento.json'

type canaisAtendimentotepy = {
  canal: string;
}

const ComoRegistrarReclamacao = () => {
  const canaisAtendimento = canaisAtendimentoJson
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='pb-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col'>
            <h2 className='fs-22 text-orange--extra mb-4'>Como registrar uma reclamação?</h2>
            <p className='fs-16 text-grayscale--500'>
              Antes de enviar uma reclamação à BSM, seu problema pode
              ser resolvido através dos nossos canais de atendimento:
            </p>
            <div>
              <ul>
                {canaisAtendimento.map((canaisAtendimentoData: canaisAtendimentotepy, index: number) => {
                return (
                  <li key={index} className='fs-16 text-grayscale--500'>
                    {canaisAtendimentoData.canal}
                  </li>
                )
              })}
              </ul>
            </div>
            <div>
              <p className='fs-16 text-grayscale--500 mt-3'>
                Se ainda assim sua situação não for solucionada, você pode acessar o
                <a
                  href='https://www.bsmsupervisao.com.br/ressarcimento-de-prejuizos/como-funciona'
                  target='_blank'
                  rel='noreferrer'
                  className='fs-16 text-orange--extra'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_04',
                      section_name: 'Como registrar uma reclamação?',
                      element_action: 'click button',
                      element_name: 'site da BSM',
                      redirect_url: 'https://www.bsmsupervisao.com.br/ressarcimento-de-prejuizos/como-funciona',
                    })
                  }}
                > site da BSM
                </a> e seguir a trilha
                <span className='fw-600'> MRP {'>'} Envie sua reclamação {'>'} Envie sua reclamação ao MRP </span> e seguir o passo a passo indicado na página da BSM, para registrar sua reclamação.
              </p>
              <p className='fs-16 text-grayscale--500 mt-3'>
                Após esse procedimento, a corretora será notificada e a
                BSM julgará se o ressarcimento será aplicado ao caso, caso a
                reclamação atenda a todas as exigências estabelecidas pela entidade.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ComoRegistrarReclamacao
