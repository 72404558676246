import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import { Hero } from './sections/Hero/_index'
import MecanismoRessarcimentoPrejuizos from './sections/mecanis-ressarcimento-prejuizo/_index'
import SituacoesQueGeramRessarcimento from './sections/situacoes-que-geram-ressarcimento/_index'
import ComoRegistrarReclamacao from './sections/como-registrar-reclamacao/_index'

import { Wrapper } from './style'

const CanaisDeAtendimento = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <MecanismoRessarcimentoPrejuizos />
        <SituacoesQueGeramRessarcimento />
        <ComoRegistrarReclamacao />
      </Layout>
    </Wrapper>
  )
}

export default CanaisDeAtendimento
