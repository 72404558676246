import React from 'react'

const MecanismoRessarcimentoPrejuizos = () => {
  return (
    <section className='pt-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col'>
            <h2 className='fs-22 text-orange--extra text-center mb-4'>Mecanismo de Ressarcimento de Prejuízos</h2>
            <p className='fs-16 text-grayscale--500'>O <span className='fw-600'> Mecanismo de Ressarcimento de Prejuízos (MRP) </span> é um sistema mantido pela B3 e administrado pela BSM Supervisão de Mercados, instituído com a finalidade exclusiva de garantir aos investidores o ressarcimento de prejuízos em casos de eventuais erros ou omissões causados por agentes do mercado, como corretoras e distribuidoras de títulos e valores mobiliários.</p>
            <p className='fs-16 text-grayscale--500'>O MRP pode ser aplicado para operações realizadas na bolsa com valores mobiliários (como compra e venda de ações, derivativos e fundos listados) e serviços de custódia, sendo o ressarcimento limitado ao valor de R$ 120 mil reais por ocorrência.</p>
            <p className='fs-16 text-grayscale--500'>Importante ressaltar que os ressarcimentos não se aplicam a títulos de renda fixa (CDBs, LCIs, LCAs etc.), a investimentos em títulos do Tesouro Direto e operações realizadas no mercado de balcão.</p>
            <p className='fs-16 text-grayscale--500'>Além disso, o MRP também cobre prejuízos decorrentes da intervenção ou decretação da liquidação extrajudicial de Participante pelo Banco Central Brasil.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MecanismoRessarcimentoPrejuizos
