import React from 'react'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import casosMrpJson from '../../assets/data/casos-mrp.json'

type casostepy = {
  icon: string;
  description: string;
}

const SituacoesQueGeramRessarcimento = () => {
  const casosMRP = casosMrpJson
  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col'>
            <h2 className='fs-22 text-orange--extra mb-4'>Quais situações podem gerar ressarcimento?</h2>
            <p className='fs-16 text-grayscale--500'>As reclamações ao MRP podem ser apresentadas no <span className='fw-600'>prazo de até 18 meses</span> após a data da ocorrência do fato que teria gerado prejuízo. O MRP pode ser acionado apenas em casos de:</p>
            <div>
              {casosMRP.map((casosData: casostepy, index: number) => {
                return (
                  <div key={index} className='col-12 px-0'>
                    <div className='d-flex mb-3'>
                      <div className='col-2 col-md-1'>
                        <OrangeIcon size='MD' color='#ff7a00' icon={casosData.icon} className='ml-2 mt-2' />
                      </div>
                      <div className='col-10 col-md-11'>
                        <p className='fs-16 text-grayscale--400'>{casosData.description}</p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div>
              <p className='fs-14 text-grayscale--500 mt-3'>Vale esclarecer que oscilações de preços de ações ou de qualquer outro ativo que decorram, exclusivamente, dos movimentos de mercado não são ressarcidas. Casos de recuperação judicial ou falência da empresa que emitiu o título ou as ações também não são cobertos pelo MRP.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SituacoesQueGeramRessarcimento
